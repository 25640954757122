<template>
<FMSignatureGeneratorMainDialog
  :value="true"
  :is-persistent="true"
/>
</template>

<script>

export default {
  name: "FMSignatureGeneratorView",
  components: {
    FMSignatureGeneratorMainDialog: () => import("@/components/Settings/DocumentsGenerator/FMSignature/FMSignatureGeneratorMainDialog")
  }
}
</script>

<style scoped>

</style>